import { MenuList, Stack } from "@mui/material";

import ChipAssist from "../Chip/Assist";
import Popup from "../Popup";
import ListItemEntity from "../List/ListItem";
import { HoverCard } from "../Card/Task";

export default function StackOverflow({
  cards = [],
  Card = () => {},
  entityName = "paper",
  max = 1,
  small
}) {
  const preview = cards.slice(0, max);
  const remaining = Math.max(cards.length - max, 0);

  return (
    <Stack
      useFlexGap
      spacing={1}
      pt={1}
      flexWrap="wrap"
      direction="row"
      alignItems="center"
    >
      {preview.map(entity => (
        <Card small={small} key={entity.id} {...{ [entityName]: entity }} />
      ))}
      {remaining === 0 ? null : (
        <Popup
          minWidth={280}
          Component={() => (
            <MenuList
              onClick={event => event.stopPropagation()}
              sx={{ bgcolor: "surface.container.lowest" }}
            >
              {cards.slice(max).map(entity => (
                <HoverCard key={entity.id} tag={entity}>
                  <ListItemEntity entity={entity} />
                </HoverCard>
              ))}
            </MenuList>
          )}
        >
          <span>
            <ChipAssist
              small={small}
              label={`+ ${remaining}`}
              onClick={event => event.preventDefault()}
            />
          </span>
        </Popup>
      )}
    </Stack>
  );
}
